<template>
  <div class="flex flex-col gap-3">
    <div
      class="flex flex-row vx-col sm:w-5/6 w-full whitespace-no-wrap justify-between items-center"
    >
      <h4>Schedule {{ index + 1 }}</h4>
    </div>
    <div class="flex flex-row gap-3 w-full whitespace-no-wrap items-end">
      <div class="flex flex-col gap-3" style="min-width: 250px">
        <label>Entry Schedule</label>
        <div class="w-full whitespace-no-wrap">
          <multiselect
            class="selectExample"
            v-model="selectedEntrySchedule"
            :options="
              !entryScheduleOptions || entryScheduleOptions.length == 0
                ? []
                : entryScheduleOptions
            "
            :multiple="false"
            :allow-empty="false"
            :group-select="false"
            :max-height="160"
            :limit="4"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            :disabled="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div
        v-if="selectedEntrySchedule && selectedEntrySchedule.id === 1"
        class="flex flex-col gap-3"
        style="min-width: 250px"
      >
        <label>Date</label>
        <div class="w-full whitespace-no-wrap">
          <multiselect
            class="selectExample"
            v-model="selectedDate"
            :options="
              !dateOptions || dateOptions.length == 0 ? [] : dateOptions
            "
            :multiple="false"
            :allow-empty="true"
            :group-select="false"
            :max-height="160"
            placeholder=" Type to search"
            track-by="id"
            label="name"
            :disabled="false"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title"> {{ props.option.name }}</span>
              </div>
            </template>
          </multiselect>
        </div>
      </div>
      <div v-else class="flex flex-row gap-3">
        <div class="flex flex-col gap-3" style="min-width: 250px">
          <label>Week</label>
          <div class="w-full whitespace-no-wrap">
            <multiselect
              class="selectExample"
              v-model="selectedWeek"
              :options="
                !weekOptions || weekOptions.length == 0 ? [] : weekOptions
              "
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"> {{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>

        <div class="flex flex-col gap-3" style="min-width: 250px">
          <label>Day</label>
          <div class="w-full whitespace-no-wrap">
            <multiselect
              class="selectExample"
              v-model="selectedDay"
              :options="!dayOptions || dayOptions.length == 0 ? [] : dayOptions"
              :multiple="false"
              :allow-empty="true"
              :group-select="false"
              :max-height="160"
              placeholder=" Type to search"
              track-by="id"
              label="name"
              :disabled="false"
            >
              <template slot="option" slot-scope="props">
                <div class="option__desc">
                  <span class="option__title"> {{ props.option.name }}</span>
                </div>
              </template>
            </multiselect>
          </div>
        </div>
      </div>
      <vs-button
        v-if="!isFirstSchedule"
        color="danger"
        type="border"
        icon="close"
        @click="removeSchedule"
      ></vs-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: Number,
    isFirstSchedule: {
      type: Boolean,
      default: true,
    },
    scheduleData: Object,
  },
  data() {
    return {
      entryScheduleOptions: [
        { id: 1, name: "Date" },
        { id: 2, name: "Week" },
      ],
      selectedEntrySchedule: { id: 1, name: "Day" },
      dateOptions: [],
      selectedDate: {
        id: 1,
        name: "1",
        value: 1,
      },
      weekOptions: [
        { id: 1, name: "Week 1", value: 1 },
        { id: 2, name: "Week 2", value: 2 },
        { id: 3, name: "Week 3", value: 3 },
        { id: 4, name: "Week 4", value: 4 },
        { id: 5, name: "All Week", value: 5 },
      ],
      selectedWeek: { id: 1, name: "Week 1", value: 1 },
      dayOptions: [
        { id: 1, name: "Senin", value: 1 },
        { id: 2, name: "Selasa", value: 2 },
        { id: 3, name: "Rabu", value: 3 },
        { id: 4, name: "Kamis", value: 4 },
        { id: 5, name: "Jumat", value: 5 },
        { id: 6, name: "Sabtu", value: 6 },
        { id: 7, name: "Minggu", value: 7 },
      ],
      selectedDay: { id: 1, name: "Senin", value: 1 },
    };
  },
  mounted() {
    for (let i = 0; i < 31; i++) {
      const newData = {
        id: i + 1,
        name: (i + 1).toString(),
        value: i + 1,
      };
      this.dateOptions.push(newData);
    }
    // console.log(object);
    this.selectedEntrySchedule = this.entryScheduleOptions.find(
      (entry) => entry.name.toLowerCase() === this.scheduleData.type.toLowerCase()
    );

    this.selectedDate = this.dateOptions.find(
      (date) => date.value === this.scheduleData.value
    );

    this.selectedWeek = this.weekOptions.find(
      (week) => week.value === this.scheduleData.week_of_month
    );
    this.selectedDay = this.dayOptions.find(
      (day) => day.value === this.scheduleData.value
    );
  },
  methods: {
    removeSchedule() {
      this.$emit("removeSchedule", this.index);
    },
    updateData() {
      let type = this.selectedEntrySchedule.name;
      type = type.toLowerCase();
      const updateData = {
        type,
        week_of_month: type === "date" ? 0 : this.selectedWeek.value,
        value:
          type === "date" ? this.selectedDate.value : this.selectedDay.value,
      };

      this.$emit("updateScheduleData", updateData, this.index);
    },
  },
  watch: {
    selectedEntrySchedule() {
      if (!this.selectedEntrySchedule) {
        return;
      }
      if (this.selectedEntrySchedule.id === 1) {
        this.selectedWeek = this.weekOptions[0];
      }
      this.updateData();
    },
    selectedDay() {
      this.updateData();
    },
    selectedWeek() {
      this.updateData();
    },
    selectedDate() {
      this.updateData();
    },
  },
};
</script>
