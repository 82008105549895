<template>
  <vs-table :data="soldToData">
    <template slot="thead">
      <vs-th> Code </vs-th>
      <vs-th> Name </vs-th>
      <vs-th> Address </vs-th>
    </template>

    <template slot-scope="{ data }">
      <vs-tr :key="indextr" v-for="(tr, indextr) in data">
        <vs-td :data="tr.code">
          {{ tr.code }}
        </vs-td>

        <vs-td :data="tr.name">
          {{ tr.name }}
        </vs-td>

        <vs-td :data="tr.address">
          {{ tr.address }}
        </vs-td>
      </vs-tr>
    </template>
  </vs-table>
</template>

<script>
export default {
  props: {
    soldToData: Array,
  },
  data() {
    return {};
  },
};
</script>
